// import * as React from 'react';
// import styled from '@emotion/styled';
// import { useEditorHandlers } from './@editor';
// import { useTranslation } from 'react-i18next';
// import { useHighlightHandlers } from '../../contexts/HighlightContext/Highlight';
// import { useAppContext } from '../../contexts/AppContext';
// import Button from '@mui/material/Button';
// import { Typography as MuiTypography } from '@mui/material';
// import { Link as LinkComponent } from '../Link';
// import { ImageLoaderWrapper } from '../ImageLoaderWrapper';
// import Image, { ImageLoaderProps } from 'next/image';

// interface CategoryChunkProps {
//   id: number;
//   name: string;
//   slug: string;
// }

// const Container = styled('div')(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'column',
// }));

// const Root = styled('div')(({ theme }) => ({
//   marginTop: 'auto',
//   color: theme.theme_component_footer_color || '#B0B1B7',
//   '& a': {
//     color: 'inherit',
//     textDecoration: 'none',
//   },
//   '& h3': {
//     color: theme.theme_component_footer_h3_color || '#83ae30',
//     marginBottom: theme.spacing(1),
//     [theme.breakpoints.up('md')]: {
//       marginBottom: theme.spacing(0.5),
//     },
//   },
// }));

// const Grid = styled('div')(({ theme }) => ({
//   display: 'grid',
//   gridTemplateColumns: 'repeat(1, 1fr)',
//   gridTemplateRows: '1fr',
//   gridColumnGap: theme.spacing(2),
//   gridRowGap: theme.spacing(2),
//   padding: theme.spacing(4),
//   backgroundColor: theme.theme_component_footer_background_color || '#000000',
//   [theme.breakpoints.between('sm', 'md')]: {
//     gridTemplateColumns: 'repeat(2, 1fr)',
//     '& > div:first-child': {
//       gridColumn: 'span 2',
//     },
//     '& > div:nth-child(2)': {
//       gridColumn: 'span 2',
//     },
//   },
//   [theme.breakpoints.up('lg')]: {
//     gridTemplateColumns: '1fr 2fr repeat(2, 1fr)',
//   },
// }));

// const GridItem = styled('div')(({ theme }) => ({
//   justifySelf: 'center',
//   textAlign: 'center',
//   overflow: 'hidden',
//   width: '100%',
//   [theme.breakpoints.up('sm')]: {
//     textAlign: 'left',
//     justifySelf: 'stretch',
//   },
// }));

// const ButtonLogo = styled(Button)(({ theme }) => ({
//   display: 'block',
//   width: '100%',
//   height: '50px',
//   margin: 'auto',
//   [theme.breakpoints.up('lg')]: {
//     margin: 'auto auto auto 0',
//   },
// })) as typeof Button;

// const ImageLogo = styled(ImageLoaderWrapper)(() => ({
//   display: 'block',
//   width: '100%',
//   height: 'auto',
// })) as typeof ImageLoaderWrapper;

// const GridListContainer = styled('div')(({ theme }) => ({
//   display: 'grid',
//   gridTemplateColumns: 'repeat(1, 1fr)',
//   gridTemplateRows: '1fr',
//   gridColumnGap: theme.spacing(2),
//   gridRowGap: 0,
//   [theme.breakpoints.up('sm')]: {
//     gridTemplateColumns: 'repeat(2, 1fr)',
//   },
// }));

// const List = styled('ul')(() => ({
//   listStyle: 'none',
//   margin: 0,
//   padding: 0,
// }));

// const ListItem = styled('li')(() => ({
//   textOverflow: 'ellipsis',
//   whiteSpace: 'nowrap',
//   overflow: 'hidden',
// }));

// const GridListItem = styled(ListItem)(({ theme }) => ({
//   padding: theme.spacing(1, 0),
//   [theme.breakpoints.up('md')]: {
//     padding: 0,
//   },
// }));

// const AzerionBrandContainer = styled('div')(() => ({
//   backgroundColor: 'black',
//   color: 'white',
// }));

// const AzerionBrandContent = styled('div')(() => ({
//   backgroundColor: 'black',
//   color: 'white',
//   marginLeft: 30,
//   padding: '10px 0',
// }));

// const splitEvery = (array: any[], length: number) =>
//   array.reduce((result, item, index) => {
//     if (index % length === 0) result.push([]);
//     result[Math.floor(index / length)].push(item);
//     return result;
//   }, []);

// const handleCookieClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
//   event.preventDefault();
//   if (window.OneTrust?.ToggleInfoDisplay) {
//     window.OneTrust?.ToggleInfoDisplay();
//   }
// };

// const Typography = React.forwardRef<HTMLInputElement, any>((props, ref) =>
//   useHighlightHandlers(MuiTypography, { forwardedRef: ref, ...props })
// );

// const Link = React.forwardRef<HTMLAnchorElement, any>((props, ref) =>
//   useHighlightHandlers(LinkComponent, { forwardedRef: ref, ...props })
// );

// export const FooterBarB = () => {
//   const { t } = useTranslation('footer_bar_b');
//   const appContext = useAppContext();
//   const { url, assetUrl, footerLogoId, componentOptions } = appContext.useConfig();
//   const { categories } = appContext.useCategories();
//   const cookieManagementEnabled = componentOptions.component_footer_bar_cookie_management_enabled;
//   const logoUrl = componentOptions.component_footer_bar_logo_url;
//   const privacyUrl = componentOptions.component_footer_bar_privacy_url;
//   const termsUrl = componentOptions.component_footer_bar_terms_url;
//   const contactUrl = componentOptions.component_footer_bar_contact_url;
//   const cookiesUrl = componentOptions.component_footer_bar_cookies_url;
//   const azerionBrand = componentOptions?.component_footer_bar_azerion_footer;
//   const categoriesHeaderRef = React.useRef<HTMLInputElement | null>(null);
//   const infoHeaderRef = React.useRef<HTMLInputElement | null>(null);
//   const contactUrlRef = React.useRef<HTMLAnchorElement | null>(null);
//   const privacyUrlRef = React.useRef<HTMLAnchorElement | null>(null);
//   const termsUrlRef = React.useRef<HTMLAnchorElement | null>(null);
//   const cookiesUrlRef = React.useRef<HTMLAnchorElement | null>(null);
//   const cookieManagementRef = React.useRef<HTMLAnchorElement | null>(null);
//   const linkRefs = React.useRef<React.Ref<HTMLAnchorElement> | []>([]);

//   // Todo: Fix linkRefs.current typescript problem.
//   // @ts-ignore
//   linkRefs.current = categories.map((category: CategoryChunkProps, index: number) => {
//     // @ts-ignore
//     return linkRefs.current[index] ?? React.createRef();
//   });

//   return (
//     <Container>
//       <Root {...useEditorHandlers('Footer Bar B')}>
//         <Grid>
//           <GridItem>
//             {footerLogoId &&
//               (logoUrl ? (
//                 <ButtonLogo component={Link} href={logoUrl} target="_blank" rel="noreferrer">
//                   <ImageLogo
//                     width={200}
//                     height={60}
//                     src={`${assetUrl}${footerLogoId}/`}
//                     alt={logoUrl}
//                     sizes="200px"
//                     layout="responsive"
//                   />
//                 </ButtonLogo>
//               ) : (
//                 <ButtonLogo component={Link} href="/" aria-label="Home" title={url}>
//                   <Image
//                     loader={({ src }: ImageLoaderProps) => src}
//                     layout="fill"
//                     objectFit="contain"
//                     unoptimized={true}
//                     src={`${assetUrl}${footerLogoId}/`}
//                     alt={url}
//                   />
//                 </ButtonLogo>
//               ))}
//           </GridItem>
//           <GridItem>
//             <Typography ref={categoriesHeaderRef} variant="h6" component="h3">
//               {t('header_categories', 'Categories')}
//             </Typography>
//             <GridListContainer>
//               {categories &&
//                 splitEvery(categories, 4).map((chunk: [], index: number) => (
//                   <List key={index}>
//                     {chunk.map((category: CategoryChunkProps, i: number) => (
//                       <GridListItem key={category.id}>
//                         <Link
//                           // @ts-ignore
//                           ref={linkRefs.current[i + index * 4]}
//                           href={`/category/${category.slug}`}
//                           variant="subtitle2"
//                           underline="hover"
//                           title={category.name}
//                         >
//                           {category.name}
//                         </Link>
//                       </GridListItem>
//                     ))}
//                   </List>
//                 ))}
//             </GridListContainer>
//           </GridItem>
//           <GridItem>
//             <Typography ref={infoHeaderRef} variant="h6" component="h3">
//               {t('header_info', 'Info')}
//             </Typography>
//             <List>
//               <GridListItem>
//                 {contactUrl ? (
//                   <Link
//                     ref={contactUrlRef}
//                     href={contactUrl}
//                     target="_blank"
//                     rel="noreferrer"
//                     variant="subtitle2"
//                     underline="hover"
//                     title={t('contact', 'Contact')}
//                   >
//                     {t('contact', 'Contact')}
//                   </Link>
//                 ) : (
//                   <Link
//                     ref={contactUrlRef}
//                     href="/contact"
//                     variant="subtitle2"
//                     underline="hover"
//                     title={t('contact', 'Contact')}
//                   >
//                     {t('contact', 'Contact')}
//                   </Link>
//                 )}
//               </GridListItem>
//               <GridListItem>
//                 {privacyUrl ? (
//                   <Link
//                     ref={privacyUrlRef}
//                     href={privacyUrl}
//                     target="_blank"
//                     rel="noreferrer"
//                     variant="subtitle2"
//                     underline="hover"
//                     title={t('privacy_statement', 'Privacy statement')}
//                   >
//                     {t('privacy_statement', 'Privacy statement')}
//                   </Link>
//                 ) : (
//                   <Link
//                     ref={privacyUrlRef}
//                     href="/legal/privacy-statement"
//                     variant="subtitle2"
//                     underline="hover"
//                     title={t('privacy_statement', 'Privacy statement')}
//                   >
//                     {t('privacy_statement', 'Privacy statement')}
//                   </Link>
//                 )}
//               </GridListItem>
//               <GridListItem>
//                 {termsUrl ? (
//                   <Link
//                     ref={termsUrlRef}
//                     href={termsUrl}
//                     target="_blank"
//                     rel="noreferrer"
//                     variant="subtitle2"
//                     underline="hover"
//                     title={t('terms_of_use', 'Terms of use')}
//                   >
//                     {t('terms_of_use', 'Terms of use')}
//                   </Link>
//                 ) : (
//                   <Link
//                     ref={termsUrlRef}
//                     href="/legal/terms-of-use"
//                     variant="subtitle2"
//                     underline="hover"
//                     title={t('terms_of_use', 'Terms of use')}
//                   >
//                     {t('terms_of_use', 'Terms of use')}
//                   </Link>
//                 )}
//               </GridListItem>
//               {cookieManagementEnabled && (
//                 <>
//                   <GridListItem>
//                     {cookiesUrl ? (
//                       <Link
//                         ref={cookiesUrlRef}
//                         href={cookiesUrl}
//                         target="_blank"
//                         rel="noreferrer"
//                         variant="subtitle2"
//                         underline="hover"
//                         title={t('cookie_statement', 'Cookie statement')}
//                       >
//                         {t('cookie_statement', 'Cookie statement')}
//                       </Link>
//                     ) : (
//                       <Link
//                         ref={cookiesUrlRef}
//                         href="/legal/cookie-statement"
//                         variant="subtitle2"
//                         underline="hover"
//                         title={t('cookie_statement', 'Cookie statement')}
//                       >
//                         {t('cookie_statement', 'Cookie statement')}
//                       </Link>
//                     )}
//                   </GridListItem>
//                   <GridListItem>
//                     <Link
//                       ref={cookieManagementRef}
//                       href="/"
//                       onClick={handleCookieClick}
//                       underline="hover"
//                       variant="subtitle2"
//                       title={t('cookie_management', 'Cookie management')}
//                     >
//                       {t('cookie_management', 'Cookie management')}
//                     </Link>
//                   </GridListItem>
//                 </>
//               )}
//             </List>
//           </GridItem>
//         </Grid>
//       </Root>
//       {azerionBrand && (
//         <AzerionBrandContainer>
//           <AzerionBrandContent>
//             <Image
//               src="/images/azerion-brand.png"
//               loader={({ src, width }: ImageLoaderProps) => `${src}?w=${width || 180}`}
//               alt="made-with-love"
//               height="30"
//               width="180"
//             />
//           </AzerionBrandContent>
//         </AzerionBrandContainer>
//       )}
//     </Container>
//   );
// };


import * as React from 'react';
import styled from '@emotion/styled';
import { useEditorHandlers } from './@editor';
import { useTranslation } from 'react-i18next';
import { useHighlightHandlers } from '../../contexts/HighlightContext/Highlight';
import { useAppContext } from '../../contexts/AppContext';
import Button from '@mui/material/Button';
import { Typography as MuiTypography } from '@mui/material';
import { Link as LinkComponent } from '../Link';
import { ImageLoaderWrapper } from '../ImageLoaderWrapper';
import Image, { ImageLoaderProps } from 'next/image';

// icons"
import FacebookIcon from './icons/facebook';
import YoutubeIcon from './icons/youtube';
import TwitterIcon from './icons/twitter';
import InstagramIcon from './icons/instagram';

interface CategoryChunkProps {
  id: number;
  name: string;
  slug: string;
}

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

const Root = styled('div')(({ theme }) => ({
  marginTop: 'auto',
  color: theme.theme_component_footer_color || '#B0B1B7',
  backgroundColor: theme.theme_component_footer_background_color || '#000000',
  padding: theme.spacing(5),
  '& a': {
    color: 'inherit',
    textDecoration: 'none',
  },
  '& h3': {
    color: theme.theme_component_footer_h3_color || '#83ae30',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(0.5),
    },
  },
}));

const Grid = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    paddingBottom: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
    // display: 'none'
  }
}));

const GridItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    textAlign: 'left',
    justifySelf: 'stretch',
  },
  [theme.breakpoints.down('md')]: {
    textAlign: 'left',
    flexDirection: 'column',
    justifySelf: 'stretch',
  },
}));

const FooterNavMenu = styled('nav')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0, 4),
  '& a': {
    fontSize: '12px'
  },
  [theme.breakpoints.down('sm')]: {
    display: 'inline-block',
    textAlign: 'center',
    '& a': {
      margin: theme.spacing(1.5, 2),
      display: 'inline-block'
    },
  },
}));

const ButtonLogo = styled(Button)(({ theme }) => ({
  display: 'block',
  width: '100%',
  height: '50px',
  margin: 'auto',
  [theme.breakpoints.up('lg')]: {
    margin: 'auto auto auto 0',
  },
})) as typeof Button;

const ImageLogo = styled(ImageLoaderWrapper)(() => ({
  display: 'block',
  width: '100%',
  height: 'auto',
})) as typeof ImageLoaderWrapper;


const AzerionBrandContainer = styled('div')(() => ({
  backgroundColor: 'black',
  color: 'white',
}));

const AzerionBrandContent = styled('div')(() => ({
  backgroundColor: 'black',
  color: 'white',
  marginLeft: 30,
  padding: '10px 0',
}));

const splitEvery = (array: any[], length: number) =>
  array.reduce((result, item, index) => {
    if (index % length === 0) result.push([]);
    result[Math.floor(index / length)].push(item);
    return result;
  }, []);

const handleCookieClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  event.preventDefault();
  if (window.OneTrust?.ToggleInfoDisplay) {
    window.OneTrust?.ToggleInfoDisplay();
  }
};

const Typography = React.forwardRef<HTMLInputElement, any>((props, ref) =>
  useHighlightHandlers(MuiTypography, { forwardedRef: ref, ...props })
);

const Title = styled(MuiTypography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '14px',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
}));

const Link = React.forwardRef<HTMLAnchorElement, any>((props, ref) =>
  useHighlightHandlers(LinkComponent, { forwardedRef: ref, ...props })
);

export const FooterBarB = () => {
  const { t } = useTranslation('footer_bar_b');
  const appContext = useAppContext();
  const { url, assetUrl, footerLogoId, componentOptions } = appContext.useConfig();
  const { categories } = appContext.useCategories();
  const logoUrl = componentOptions.component_footer_bar_logo_url;
  const azerionBrand = componentOptions?.component_footer_bar_azerion_footer;
  const linkRefs = React.useRef<React.Ref<HTMLAnchorElement> | []>([]);

  const youtubeUrl = componentOptions.component_footer_bar_youtube_url;
  const instagramUrl = componentOptions.component_footer_bar_instagram_url;
  const twitterUrl = componentOptions.component_footer_bar_twitter_url;
  const facebookUrl = componentOptions.component_footer_bar_facebook_url;

  // Todo: Fix linkRefs.current typescript problem.
  // @ts-ignore
  linkRefs.current = categories.map((category: CategoryChunkProps, index: number) => {
    // @ts-ignore
    return linkRefs.current[index] ?? React.createRef();
  });

  return (
    <Container>
      <Root {...useEditorHandlers('Footer Bar F')}>
        <Grid className='123123123132'>
          <GridItem>
          {footerLogoId &&
              (logoUrl ? (
                <ButtonLogo component={Link} href={"https://cnn.staging.byorbit.com/media/8203640d-7aa1-49a4-ac24-13faecd1c602"} target="_blank" rel="noreferrer">
                  <ImageLogo
                    width={200}
                    height={60}
                    src={`${assetUrl}${footerLogoId}/`}
                    alt={logoUrl}
                    sizes="200px"
                    layout="responsive"
                  />
                </ButtonLogo>
              ) : (
                <ButtonLogo component={Link} href="/" aria-label="Home" title={url}>
                  <Image
                    loader={({ src }: ImageLoaderProps) => src}
                    layout="fill"
                    objectFit="contain"
                    unoptimized={true}
                    src={"https://cnn.staging.byorbit.com/media/8203640d-7aa1-49a4-ac24-13faecd1c602"}
                    alt={url}
                  />
                </ButtonLogo>
              ))}
          </GridItem>
          <GridItem>
            <Title style={{ fontWeight: 'bold', fontSize: '14px' }}>FOLLOW CNN</Title>
            <div>
              {!facebookUrl && (
                <a href={facebookUrl} target="_blank" rel="noreferrer" title="Facebook">
                  <FacebookIcon style={{ marginRight: 20 }} className={undefined} />
                </a>
              )}
              {!twitterUrl && (
                <a href={twitterUrl} target="_blank" rel="noreferrer" title="Twitter">
                  <TwitterIcon style={{ marginRight: 20 }} className={undefined} />
                </a>
              )}
              {!youtubeUrl && (
                <a href={youtubeUrl} target="_blank" rel="noreferrer" title="Youtube">
                  <YoutubeIcon style={{ marginRight: 20 }} className={undefined} />
                </a>
              )}
              {!instagramUrl && (
                <a href={instagramUrl} target="_blank" rel="noreferrer" title="Instagram">
                  <InstagramIcon style={{ marginRight: 20 }} className={undefined} />
                </a>
              )}
            </div>
          </GridItem>
        </Grid>
        <FooterNavMenu className="footer__links" data-editable="footerLinks">
          <a href="/terms" className="footer__link">Terms of Use</a>
          <a href="#" className="uc_pp_link footer__link"></a>
          <a href="#" className="uc_cs_link footer__link"></a>
          <a href="#" className="uc_ac_link footer__link"></a>
          <a href="/accessibility" className="footer__link">Accessibility &amp; CC</a>
          <a href="/about" className="footer__link">About</a>
          <a href="/transcripts" className="footer__link">Transcripts</a>
        </FooterNavMenu>
      </Root>
      {azerionBrand && (
        <AzerionBrandContainer>
          <AzerionBrandContent>
            <Image
              src="/images/azerion-brand.png"
              loader={({ src, width }: ImageLoaderProps) => `${src}?w=${width || 180}`}
              alt="made-with-love"
              height="30"
              width="180"
            />
          </AzerionBrandContent>
        </AzerionBrandContainer>
      )}
    </Container>
  );
};
